import { combineReducers } from "redux"
import authReducer from "./authReducer"
import eventReducer from "./eventReducer"
import ngoReducer from "./ngoReducer"
import promotionReducer from "./promotionReducer"
import scheduleReducer from "./scheduleReducer"
import transactionReducer from "./transactionReducer"
import utilsReducer from "./utilsReducer"

const rootReducer = combineReducers({
  authStore: authReducer,
  utilsStore: utilsReducer,
  scheduleStore: scheduleReducer,
  promotionStore: promotionReducer,
  transactionStore: transactionReducer,
  ngoStore: ngoReducer,
  eventStore: eventReducer,
})

export default rootReducer
