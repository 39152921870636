import { UserModel } from "../../models/User"

export interface authStateInterface {
  userAuth: any
  user: UserModel | null
  userLoading: boolean
  referrals: Object[]
  referralLoading: boolean
}

const initState = {
  userAuth: null,
  user: null,
  userLoading: true,
  referrals: [],
  referralLoading: true,
}

const authReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_USER_AUTH":
      return {
        ...state,
        userAuth: action.payload.userAuth,
        user: action.payload.user,
        userLoading: false,
      }
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload.user,
      }
    case "UPDATE_USER_REFERRALS":
      return {
        ...state,
        referralLoading: false,
        referrals: action.payload.referrals,
      }
    default:
      return state
  }
}

export default authReducer
