import EventModel from "../../models/Event"

export interface eventStateInterface {
  events: EventModel[]
  loading: boolean
  lastCursor: Date | string
}

const initState: eventStateInterface = {
  events: [],
  loading: false,
  lastCursor: "",
}

const eventReducer: any = (state = initState, action: any) => {
  switch (action.type) {
    case "UPDATE_EVENT_LIST":
      return {
        ...state,
        events: action.payload.events,
        lastCursor: action.payload.lastCursor,
      }
    case "UPDATE_EVENT_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      }
    default:
      return state
  }
}

export default eventReducer
