import axios from "axios"
import {
  Aggregate,
  Query,
  ReadQuery,
  Sort,
} from "blixify-ui-web/lib/components/data/readQuery"
import { WriteQuery } from "blixify-ui-web/lib/components/data/writeQuery"

const isProd = process.env.GATSBY_FIREBASE_ENV === "production"
export const apiPrefix = `https://api${isProd ? "" : "-dev"}.arusoil.com`
export const queryAPI = `${apiPrefix}/api/data`
export const assetAPI = `${apiPrefix}/api/asset`

export const getIdToken = async (firebase: any) => {
  let idTokenResult = ""
  try {
    idTokenResult = await firebase.auth().currentUser.uid
  } catch (err) {}
  return idTokenResult
}

export class CustomRQ {
  collection = ""
  firebase: any = null
  call = async (cond: {
    type: "get" | "list"
    id?: string
    query?: Query[]
    cursor?: any
    limit?: any
    sort?: Sort
    aggregate?: Aggregate
    stopLimit?: boolean
  }) => {
    const idToken = await getIdToken(this.firebase)
    return new ReadQuery({
      dbModule: axios,
      dbType: "mongoServer",
      dbCollection: this.collection,
      dbEndpoint: queryAPI,
      dbAPIToken: undefined,
      dbUserToken: idToken,
    }).call(cond)
  }

  constructor(collection: string, firebase: any) {
    this.collection = collection
    this.firebase = firebase
  }
}

export class CustomWQ {
  collection = ""
  firebase: any = null
  call = async (
    queryType: "create" | "update" | "delete" | "batchCreate" | "batchDelete",
    queryData: {
      id?: string | string[]
      data?: any
      query?: Query[]
    }
  ) => {
    const idToken = await getIdToken(this.firebase)
    return new WriteQuery({
      dbModule: axios,
      dbType: "mongoServer",
      dbCollection: this.collection,
      dbEndpoint: queryAPI,
      dbAPIToken: undefined,
      dbUserToken: idToken,
    }).call(queryType, queryData)
  }

  constructor(collection: string, firebase: any) {
    this.collection = collection
    this.firebase = firebase
  }
}
